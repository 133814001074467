import React from "react"
import { FaLinkedin, FaEnvelope, FaGithub, FaFilePdf } from "react-icons/fa"

import AppLayout from "../../Components/AppLayout"
import { Text, Box, Flex } from "../../Components/Base"

const Contact = () => {
  return (
    <>
      <Flex alignItems={"center"} mb={1}>
        <FaEnvelope size={16} />
        <Text fontSize={2} ml={2}>
          <a target={"_blank"} href={"mailto: tadpol.rach@gmail.com"}>
            tadpol.rach@gmail.com
          </a>
        </Text>
      </Flex>
      <Flex alignItems={"center"} mb={1}>
        <FaLinkedin size={16} />
        <Text fontSize={2} ml={2}>
          <a target={"_blank"} href={"https://www.linkedin.com/in/tadpolrach/"}>
            linkedin.com/in/tadpolrach
          </a>
        </Text>
      </Flex>
      <Flex alignItems={"center"} mb={1}>
        <FaGithub size={16} />
        <Text fontSize={2} ml={2}>
          <a target={"_blank"} href={"https://github.com/tadpolr"}>
            github.com/tadpolr
          </a>
        </Text>
      </Flex>
      <Flex alignItems={"center"}>
        <FaFilePdf size={16} />
        <Text fontSize={2} ml={2}>
          <a
            target={"_blank"}
            href={
              "https://drive.google.com/file/d/1UjFkuB7jkbyD9SCjZD07NaL6Fc5z7PYg/view?usp=sharing"
            }
          >
            Resume
          </a>
        </Text>
      </Flex>
    </>
  )
}

export default Contact
