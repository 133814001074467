import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import AppLayout from "../../Components/AppLayout"
import { Box, Text, Flex, Image } from "../../Components/Base"
import { WORK_ID, WORK, WORK_CATEGORY } from "../../constant"
import Contact from "./Contact"

// Base Components for this route
const Title = ({ ...props }) => (
  <Text fontSize={6} fontWeight={"bold"} color={"black"} {...props} />
)

const WorkCardImage = styled(Image)`
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.1);
  }
  &:active {
    transform: translateY(-5px);
    box-shadow: 0 0 40px 7px rgba(0, 0, 0, 0.1);
  }
`
const WorkCard = ({ workId }) => {
  const { role, name, url } = WORK.find(w => w.key === workId)

  const imageUrl = `/assets/work/${workId}/thumbnail.jpg`
  return (
    <Box
      width={[
        "100%",
        "calc((100% - 32px) / 2)",
        "calc((100% - 64px) / 2)",
        "calc((100% - 96px) / 2)",
      ]}
      flex={"0 0 auto"}
    >
      <Link to={url} style={{ display: "block", textDecoration: "none" }}>
        <WorkCardImage
          src={imageUrl}
          width={"100%"}
          height={["192px", "256px"]}
          style={{ objectFit: "cover", borderRadius: "8px" }}
        />

        <Text fontSize={[5, 5, 5, 6]} fontWeight={"semibold"}>
          {name}
        </Text>
        <Text
          fontSize={[5, 5, 5, 6]}
          fontWeight={"medium"}
          color={"greyscale.grey7"}
        >
          {role}
        </Text>
      </Link>
    </Box>
  )
}

const WorkListContainer = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  /* > div:nth-child(even) {
    margin-top: 128px;
  } */
  > div {
    margin-bottom: 64px;
  }

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    > div:nth-child(even) {
      margin-top: 0;
    }
    > div {
      margin-bottom: 32px;
    }
  }
`

const Landing = () => {
  return (
    <AppLayout>
      <Text
        fontSize={[7, 8, 9]}
        width={["375px", "768px"]}
        maxWidth={"100%"}
        mr={2}
        mb={2}
      >
        {/* Hi, I'm <br /> */}
        Tadpol <br />
        Rachatasumrit
      </Text>
      <Text
        fontSize={[4]}
        color={"greyscale.grey5"}
        fontWeight={"medium"}
        maxWidth={"580px"}
        mb={4}
      >
        software engineer | designer | architect
      </Text>
      <Text
        fontSize={[4]}
        color={"greyscale.grey5"}
        // fontWeight={"medium"}
        maxWidth={"580px"}
      >
        Hi, I am an architect turned software engineer. I enjoy both coding and
        design and love to bridge them together. Hope you enjoy the stuff I
        made. <br />
        <br />
        Let's get in touch.
        {/* An architect turned software developer who enjoy both coding and design. */}
        {/* developer | designer | architect */}
      </Text>
      <Box mt={4}>
        <Contact />
      </Box>
      <Box width={"100%"} py={[5, 6]}>
        <Flex alignItems={"center"} mb={4}>
          <Box
            width={"20px"}
            height={"20px"}
            backgroundColor={"primary"}
            mr={3}
          />
          {/* <Title>FEATURED WORKS</Title> */}
          <Title>FEATURED WORKS</Title>
        </Flex>
        <WorkListContainer flexDirection={["column", "row"]} mb={4}>
          {WORK.map(w => {
            return (
              w.active &&
              w.isSelectedWork &&
              w.category === WORK_CATEGORY.DEV && (
                <WorkCard key={w.key} workId={w.key} />
              )
            )
          })}
        </WorkListContainer>
        <Flex alignItems={"center"} mb={4}>
          <Box width={"20px"} height={"20px"} backgroundColor={"grey"} mr={3} />
          <Title>ARCHITECTURE WORKS</Title>
        </Flex>
        <WorkListContainer flexDirection={["column", "row"]}>
          {WORK.map(w => {
            return (
              w.active &&
              w.isSelectedWork &&
              w.category === WORK_CATEGORY.ARCH && (
                <WorkCard key={w.key} workId={w.key} />
              )
            )
          })}
        </WorkListContainer>
        {/* <Flex alignItems={"center"} mb={4}>
          <Box
            width={"20px"}
            height={"20px"}
            backgroundColor={"primary"}
            mr={3}
          />
          <Title>More Works</Title>
        </Flex> */}
        <WorkListContainer flexDirection={["column", "row"]}>
          {WORK.map(w => {
            return (
              w.active &&
              !w.isSelectedWork && <WorkCard key={w.key} workId={w.key} />
            )
          })}
        </WorkListContainer>
      </Box>
    </AppLayout>
  )
}

export default Landing
